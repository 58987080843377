import React, { memo } from "react"
import { Typography } from "antd"
import { Link } from "gatsby"
import Img from "gatsby-image"
const { Title } = Typography
const AboutHeader = ({ isAbout, data }) => {
    const backgroundClass = isAbout
        ? "about-background"
        : "lifeAtVisiomate-background"
    const backgroundImage = isAbout
        ? data.aboutusbg.childImageSharp.fluid.src
        : data.lifeatvisiomatebg.childImageSharp.fluid.src
    return (
        <div className="aboutus-page-container">
            <div
                className={`top-section ${backgroundClass}`}
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div
                    className={`headings-container ${isAbout ? "black-text" : "white-text"
                        }`}
                >
                    <div className="link-size-for-header">
                        <Link to="/">
                            {isAbout ? (
                                <Img
                                    fluid={data.VMLogoBlack.childImageSharp.fluid}
                                    alt="VMLogoBlack"
                                    className="vmLogo"
                                />
                            ) : (
                                <Img
                                    fluid={data.VMLogoWhite.childImageSharp.fluid}
                                    alt="VMLogoWhite"
                                    className="vmLogo"
                                />
                            )}
                        </Link>
                    </div>

                    {isAbout ? (
                        <Title>
                            We take immense pride in our <span>commitment</span> to shaping
                            the <span>future</span> for our clients, ensuring their journey is{" "}
                            <span>innovatively</span> crafted and purposefully designed.
                        </Title>
                    ) : (
                        <Title>
                            Creating a <span>tranquil</span> work environment and fostering a{" "}
                            <span>vibrant</span> atmosphere are fundamental elements that
                            drive productivity in the workplace
                        </Title>
                    )}
                </div>
                {isAbout ? (
                    <div className="ArrowImage">
                        <Link to="/contact">
                            <p className="p-color-about-us">Let’s Connect</p>
                            <Img
                                fluid={data.arrowImage.childImageSharp.fluid}
                                alt="arrowImage"
                            />
                        </Link>
                    </div>
                ) : (
                    <div className="ArrowImage">
                        <Link to="/contact">
                            <p className="p-color-LAV-us">Let’s Connect</p>
                            <Img
                                fluid={data.arrowImageWhite.childImageSharp.fluid}
                                alt="arrowImageWhite"
                            />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(AboutHeader)
