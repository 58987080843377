import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/aboutUs.scss"
import AboutHeader from "../components/AboutHeader"
import OurMission from "../components/OurMission"
import Whyus from "../components/Whyus"
import News from "../components/News"
import About_clients from "../components/About_clients"

const About = ({ data }) => {
  return (
    <Layout pageTitle={"Visiomate - About Visiomate"}
    descrip = {"Learn about Visiomate expertise, experience, and commitment to providing innovative solutions."}>
      <AboutHeader isAbout={true} data={data} />
      <OurMission data={data} />
      <Whyus data={data} />
      <About_clients data={data} />
      <News isAbout={true} data={data} />
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutQuery {
    AboutUs: file(relativePath: { eq: "about/22.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    WhyUs: file(relativePath: { eq: "about/23.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowImage: file(relativePath: { eq: "Mask group (5).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arrowImageWhite: file(relativePath: { eq: "Mask group (6).png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutusbg: file(relativePath: { eq: "aboutusbg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifeatvisiomatebg: file(relativePath: { eq: "lifeatvisiomatebg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Averti: file(relativePath: { eq: "averti 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BanuMukhtar: file(relativePath: { eq: "banomukhtar 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    NetAtlantic: file(relativePath: { eq: "about/netatlantic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    OmanDataPark: file(relativePath: { eq: "image 7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ImesOman: file(relativePath: { eq: "image 8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Mtech: file(relativePath: { eq: "logo 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    OrrProtection: file(relativePath: { eq: "orr 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SoharPort: file(relativePath: { eq: "soharport 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SuiNorth: file(relativePath: { eq: "image 4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ZiePie: file(relativePath: { eq: "ziepie 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HP: file(relativePath: { eq: "hp 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    KM: file(relativePath: { eq: "image 5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Borjan: file(relativePath: { eq: "image 3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CNb: file(relativePath: { eq: "cnb 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    light: file(relativePath: { eq: "2-01 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "1-01 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    scale: file(relativePath: { eq: "4-01 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "6-01 1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
