import React, { memo } from 'react'
const About_clients = ({ data }) => {
    return (
        <div className="About-clients-within-home-page">
            <div className="happyClientAboutContainer">
                <div className="first">
                    <div>
                        <p >We're chosen by companies to power their digital journey</p>
                    </div>
                </div>
                <div className="second">
                    <div className="images-Div-1">
                        <img src={data.OrrProtection.childImageSharp.fluid.src} alt="Orr Protection" />
                        <img src={data.NetAtlantic.childImageSharp.fluid.src} alt="Net Atlantic" />
                        <img src={data.HP.childImageSharp.fluid.src} alt="HP" />
                        <img src={data.Mtech.childImageSharp.fluid.src} alt="Mtech" />
                        <img src={data.SoharPort.childImageSharp.fluid.src} alt="Sohar Port" />
                    </div>
                    <div className="images-Div-2">
                        <img src={data.Averti.childImageSharp.fluid.src} alt='Averti' />
                        <img src={data.ImesOman.childImageSharp.fluid.src} alt='Times of Oman' />
                        <img src={data.OmanDataPark.childImageSharp.fluid.src} alt='Oman Data Park' />
                        <img src={data.ZiePie.childImageSharp.fluid.src} alt='ZiePie' />
                    </div>
                    <div className="images-Div-3">
                        <img src={data.KM.childImageSharp.fluid.src} alt='Kiondo Market' />
                        <img src={data.SuiNorth.childImageSharp.fluid.src} alt='Net Atlantic' />
                        <img src={data.Borjan.childImageSharp.fluid.src} alt='Borjan' />
                        <img src={data.CNb.childImageSharp.fluid.src} alt='Cakes and Bakes' />
                        <img src={data.BanuMukhtar.childImageSharp.fluid.src} alt='Banu Mukhtar' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(About_clients)
