import React, { memo } from "react"
const OurMission = () => {
    return (
        <div>
            <div className="aboutus-page-container">
                <div className="our-mission">
                    <div className="left-side">
                        <p>
                            Partnering with visionary businesses to develop, advance, and
                            expand since 2018
                        </p>
                    </div>
                    <div className="right-side">
                        <p>
                            Visiomate was established by seasoned professionals with extensive
                            expertise in developing large-scale software, establishing web and
                            desktop-based systems forbusinesses, and navigating IT
                            advancements across diverse industries.
                        </p>
                        <p>
                            Our primary objective is to leave a tangible mark on our clients'
                            enterprises. Leveragingcutting-edge technology trends and
                            intelligent solutions, we empower our clients todistinguish
                            themselves in their respective fields. Our commitment lies in
                            crafting solutions that seamlessly align with their needs while
                            evolving alongside the growth of their businesses.
                        </p>
                        <p>
                            At Visiomate, we don't just build solutions; we architect pathways
                            to success, ensuringadaptability and scalability for our clients'
                            sustained growth in an ever-evolving digitallandscape.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(OurMission)
